<template>
  <modal
    :name="state.modalName"
    :adaptive="true"
    :max-width="600"
    height="auto"
    width="90%"
    scrollable
    reset
    class="v--modal"
    @before-open="beforeOpenModal()"
    @before-close="beforeCloseModal()"
  >
    <div class="popup__inner popup__inner--wide">
      <div class="popup-header">
        <strong class="popup-header__text h3">
          {{ state.model.title }}
        </strong>
      </div>
      <div class="popup-content">
        <ul class="logo-list">
          <li
            v-for="item in state.model.wholesalers"
            :key="item.name"
            class="logo-list-item"
          >
            <a
              :href="item.linkUrl"
              :title="item.name"
              class="logo-list-link"
              target="_blank"
              @click="trackWholesaler(item)"
            >
              <img
                :src="item.imageUrl"
                :alt="item.imageAlt"
                width="120"
                height="80"
              >
            </a>
          </li>
        </ul>
        <p
          v-if="state.model.textInfo"
          class="mt-md u-flex u-flex-align-start u-color--emperor"
        >
          <IconInfo
            class="icon mr-5px"
            width="16"
            height="16"
          />
          {{ state.model.textInfo }}
        </p>
        <template v-if="state.model.textEmail">
          <div class="mb-xxs">
            <strong>{{ state.model.titleEmail }}</strong>
          </div>
          <p>
            {{ state.model.textEmail }}
          </p>
          <a
            :href="emailLink"
            class="cta cta--transparent"
            @click="trackContactClick"
          >
            {{ state.model.emailCtaText }}
          </a>
        </template>
      </div>
      <span
        role="button"
        :aria-label="closeLabel"
        class="popup-content__button--close"
        @click="hidePopup(state.modalName)"
      />
    </div>
  </modal>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'
import IconInfo from '@/assets/icons/information-circle--transparent.svg'
import GtmHelper from '@/helpers/GtmHelper'
import useModal from '@/compositions/useModal'
import useWholesaler from '@/compositions/useWholesaler'

export default {
  components: {
    IconInfo
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)

    const {
      state
    } = useWholesaler()
    const { closeLabel } = window.arla.cms.resources.general

    const trackWholesaler = (wholesaler) => {
      const {
        productId,
        productName,
        placement,
        categoryName,
        brandName
      } = state

      GtmHelper.wholesalerClick({
        wholesalerName: wholesaler.name,
        wholesalerId: wholesaler.id,
        productId,
        productName,
        placement,
        categoryName,
        brandName
      })
    }

    const trackContactClick = () => {
      const {
        productId,
        productName,
        placement
      } = state

      GtmHelper.pushDataLayer({
        event: 'wtbOverlayContact',
        productId,
        productName,
        placement
      })
    }

    const emailLink = computed(() => `mailto:${state.model.email}`)

    return {
      state,
      emailLink,
      closeLabel,

      hidePopup,
      beforeOpenModal,
      beforeCloseModal,
      trackWholesaler,
      trackContactClick
    }
  }
}
</script>
