<template>
  <div
    :class="{ 'is-loading': state.loading }"
    class="item-card__controls"
  >
    <buy-button-business-control
      v-if="showBuyButton"
      :model="modelWithBusinessData"
      :translations="translations"
      :placement="placement"
    />
    <span
      v-else-if="isSupAuth"
      v-tooltip="{
        content: tooltipModel.supAuth.content,
        placement: 'top-start',
        trigger: 'hover focus click',
        autoHide: false,
        classes : 'error'
      }"
      role="button"
      class="cta"
      @click="supLoginClick"
    >
      {{ translations.loginLabel }}
    </span>
    <span
      v-else
      role="button"
      class="cta"
      data-login
      @click="notLoggedInClick"
    >
      {{ translations.loginLabel }}
    </span>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import useAuth from '@/compositions/useAuth'
import useMiniCart from '@/compositions/useMiniCart'
import useHeaderControls from '@/compositions/useHeaderControls'
import api from '@/lib/api'
import BuyButtonBusinessControl from '@/components/products/buy-button-business-control.vue'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: { BuyButtonBusinessControl },
  props: {
    translations: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    placement: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { isBusinessAuth, isSupAuth } = useAuth()
    const packagesInfo = ref()
    const modelWithBusinessData = computed(() => ({
      ...props.model,
      packagesInfo: [
        ...packagesInfo.value
      ]
    }))
    const showBuyButton = computed(() => isBusinessAuth.value && packagesInfo.value)
    const {
      placement,
      model: {
        ean, name, brand, category
      }
    } = props
    const trackingData = {
      productId: ean,
      productName: name,
      brandName: brand,
      categoryName: category,
      placement
    }
    const {
      state
    } = useMiniCart()

    const {
      showHeaderSidebar
    } = useHeaderControls()

    const supLoginClick = () => {
      GtmHelper.productLoginCTA(trackingData)
    }

    const notLoggedInClick = () => {
      showHeaderSidebar(false)
      GtmHelper.productLoginCTA(trackingData)
    }

    const tooltipModel = {
      notAuth: {
        content: 'Produkten ingår inte i det sortiment som ni kan beställa av oss.'
      },
      supAuth: {
        content: props.translations.privateUserBuyButtonTooltip
      }
    }

    api.getProductsPackageInfo([ean]).then(({ data }) => {
      if (data && data[ean]) {
        packagesInfo.value = data[ean]
      }
    })

    return {
      modelWithBusinessData,
      state,
      showBuyButton,
      isSupAuth,
      tooltipModel,

      notLoggedInClick,
      supLoginClick
    }
  }
}
</script>
