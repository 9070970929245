<template>
  <div
    id="recaptcha"
    data-size="invisible"
  />
</template>
<script>

export default {
  props: {
    recaptchaKey: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=renderCaptcha&render=explicit')
    document.head.appendChild(recaptchaScript)

    const getRecaptchaResponse = function (response) {
      if (response) {
        context.emit('submit', response)
      }
    }

    window.renderCaptcha = function () {
      if (window.grecaptcha) {
        window.grecaptcha.render('recaptcha', {
          sitekey: props.recaptchaKey,
          callback: getRecaptchaResponse
        })
      }
    }

    function verify() {
      if (window.grecaptcha) {
        const response = window.grecaptcha.getResponse()
        if (response.length === 0) {
          window.grecaptcha.execute()
        } else {
          context.emit('submit', response)
        }
      }
    }

    return {
      verify
    }
  }
}
</script>
