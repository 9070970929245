<template>
  <div
    :class="{ 'is-loading': isLoading }"
    class="item-card__controls"
  >
    <form @submit.prevent="onFormSubmit">
      <div class="item-card__controls-wrap">
        <input
          v-if="isBusinessAuth"
          v-model.number="amount"
          :class="{ invalid: hasError }"
          :disabled="isBusinessAuth && !isAvailable ? true : null"
          min="0"
          type="number"
          class="item-card__amount-field"
          aria-label="Amount of product"
          @focus="hasError = false"
        >
        <template v-if="isBusinessAuth && isAvailable">
          <span
            v-if="model.packagesInfo.length === 1"
            class="item-card__units"
          >
            {{ selectedPackage.orderingUnit }}
          </span>

          <select
            v-else
            :value="selectedPackage.code"
            class="form-dropdown"
            @change="selectPackage($event.target.value)"
          >
            <option
              v-for="(item) in model.packagesInfo"
              :key="item.code"
              :value="item.code"
              v-text="item.orderingUnit"
            />
          </select>
        </template>
        <button
          v-tooltip="{
            content: tooltipModel.supAuth.content,
            placement: 'top-start',
            trigger: isSupAuth ? 'hover focus click' : 'manual',
            autoHide: false,
            classes : 'error'
          }"
          type="submit"
          :disabled="isBusinessAuth && !isAvailable ? true : null"
          class="item-card__cta"
        >
          <IconCart class="icon" />
          <span>{{ translations.addToBasket }}</span>
          <cssLoader
            v-if="isLoading"
            class="css-loader"
          />
        </button>
        <Tooltip
          v-if="isBusinessAuth && !isAvailable"
          :model="tooltipModel.notAuth"
          title-color-css-class=""
        />
      </div>
      <div
        v-if="errorMessage"
        class="form-message--error"
      >
        <div v-html="translations.notificationMessage" />
      </div>
    </form>
    <span
      v-if="!isAuth"
      class="c-buy-form__overlay"
      data-login
      @click="notLoggedInClick"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import useAuth from '@/compositions/useAuth'
import useMiniCart from '@/compositions/useMiniCart'
import useHeaderControls from '@/compositions/useHeaderControls'
import IconCart from '@/assets/icons/shopping-cart.svg'
import Tooltip from '@/components/tooltip.vue'
import cssLoader from '@/components/css-loader.vue'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    IconCart,
    Tooltip,
    cssLoader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    translations: {
      type: Object,
      required: true
    },
    placement: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      isAuth, isBusinessAuth, isSupAuth
    } = useAuth()
    const {
      state, addToMiniCart
    } = useMiniCart()
    const hasError = ref(false)
    const amount = ref(null)
    const isLoading = ref(false)
    const errorMessage = ref(null)
    const {
      placement,
      model: {
        ean, name, brand, image, packagesInfo, category
      }
    } = props
    const isAvailable = packagesInfo ? packagesInfo.length : false
    const selectedPackage = ref(isAvailable ? packagesInfo[0] : null)
    const trackingData = {
      productId: ean,
      productName: name,
      brandName: brand,
      categoryName: category,
      amount: amount.value,
      placement
    }

    const {
      showHeaderSidebar
    } = useHeaderControls()

    const notLoggedInClick = () => {
      showHeaderSidebar(false)
      GtmHelper.productLoginCTA(trackingData)
    }

    const addToCart = () => {
      if (state.loading) {
        return
      }

      if (typeof amount.value !== 'number' || amount.value === 0) {
        hasError.value = true
      } else {
        hasError.value = false
        errorMessage.value = null
        isLoading.value = true
        state.loading = true

        const {
          code,
          orderingUnit: priceUnit
        } = selectedPackage.value

        addToMiniCart({
          ean,
          code,
          name,
          brand,
          category,
          amount: amount.value,
          priceUnit,
          image,
          placement
        }).then((data) => {
          if (data && !data.success) {
            errorMessage.value = data.message
            hasError.value = true
          }
          amount.value = null
        }).catch((error) => {
          errorMessage.value = error
          hasError.value = true
        }).then(() => {
          state.loading = false
          isLoading.value = false
        })
      }
    }

    const selectPackage = (code) => {
      selectedPackage.value = packagesInfo.find((item) => item.code === code)
    }

    const tooltipModel = {
      notAuth: {
        content: `Produkten ingår inte i det sortiment som ni kan beställa av oss.
            <a href="https://kund.arla.se/service/kundsupport/sa-blir-du-arla-kund/">Bli kund hos Arla</a>`
      },
      supAuth: {
        content: props.translations.privateUserBuyButtonTooltip
      }
    }

    const onFormSubmit = () => {
      if (isBusinessAuth.value) {
        addToCart()
      } else if (!isSupAuth.value) {
        notLoggedInClick()
      } else {
        GtmHelper.productLoginCTA(trackingData)
      }
    }

    return {
      state,
      amount,
      isAuth,
      isBusinessAuth,
      isSupAuth,
      hasError,
      errorMessage,
      selectedPackage,
      isAvailable,
      tooltipModel,
      isLoading,

      onFormSubmit,
      notLoggedInClick,
      selectPackage
    }
  }
}
</script>
