<template>
  <span
    :class="{ 'is-added': isAdded }"
    class="add-to-favorites"
  >
    <span
      :aria-pressed="isAdded"
      :title="buttonText"
      :class="{ 'cta': isButton }"
      role="button"
      tabindex="0"
      @click.stop.prevent="toggleFavorites"
    >
      <span class="icon-holder">
        <IconBookmark class="icon" />
      </span>
      <template v-if="!isFavoritesOverviewPage">
        {{ buttonText }}
      </template>
    </span>
  </span>
</template>

<script>
import { ref, computed, watch } from 'vue'
import useFavorites, {
  isFavorite,
  addToFavorites,
  removeFromFavorites
} from '@/compositions/favorites/favorites-id-repository'
import IconBookmark from '@/assets/icons/streamline/streamlinehq-interface-bookmark.svg'
import GtmHelper from '@/helpers/GtmHelper'
import favoritesRepository from '@/compositions/favorites/favorites-repository'
import favoritesTabsConfig from '@/compositions/favorites/favorites-tabs-configuration'
import useHeaderControls from '@/compositions/useHeaderControls'
import useAuth from '@/compositions/useAuth'
import { userIsInitiated } from '@/compositions/useUser'

export default {
  components: {
    IconBookmark
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    addText: {
      type: String,
      default: ''
    },
    removeText: {
      type: String,
      default: ''
    },
    isButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { type, item: { id, name } } = props
    const {
      removeItemById
    } = favoritesRepository.useFavoritesResult()
    const { loading } = useFavorites()
    const isAdded = ref(isFavorite(id, type))
    const {
      initialized: isFavoritesOverviewPage
    } = favoritesTabsConfig.favoritesTabsConfigurationUse()
    const { showHeaderSidebar } = useHeaderControls()
    const { isAuth } = useAuth()

    watch(userIsInitiated, () => {
      if (userIsInitiated.value) {
        isAdded.value = isFavorite(id, type)
      }
    })

    const toggleFavorites = (e) => {
      if (!isAuth.value) {
        e.preventDefault()
        showHeaderSidebar(false)
        GtmHelper.addToFavorites({
          id, name, type
        })
        return
      }

      if (!id || loading.value) {
        return
      }

      if (isAdded.value) {
        removeFromFavorites(id, type).then(() => {
          if (isFavoritesOverviewPage.value) {
            removeItemById(id)
          }
          GtmHelper.removeFromFavorites({
            id, name, type
          })

          isAdded.value = !isAdded.value
        })
      } else {
        addToFavorites([id], type).then(() => {
          GtmHelper.addToFavorites({
            id, name, type
          })

          isAdded.value = !isAdded.value
        })
      }
    }

    const buttonText = computed(() => (isAdded.value ? props.removeText : props.addText))

    return {
      isAdded,
      buttonText,
      isFavoritesOverviewPage,

      toggleFavorites
    }
  }
}
</script>
