<template>
  <form
    class="form--default form--has-loader"
    :class="{ 'is-loading': loading }"
    @submit.prevent="onSubmit"
  >
    <component
      :is="currentStep.component"
      v-model="model"
    />
    <div
      v-if="errorMessage"
      class="form-row form-message--error"
      v-html="errorMessage"
    />
    <div class="form__controls">
      <button
        v-if="currentStepNumber !== 1"
        type="button"
        class="cta cta--transparent"
        @click="onPrevButtonClick"
        v-text="modalPreviousStepButtonText"
      />
      <button
        type="submit"
        class="cta"
        v-text="submitButtonText"
      />
    </div>
    <recaptcha
      ref="recaptchaForm"
      :recaptcha-key="recaptchaKey"
      @submit="onSubmitCatalogue"
    />

    <loader :loading="loading" />
  </form>
</template>

<script>
import { ref, computed } from 'vue'
import Recaptcha from '@/components/recaptcha.vue'
import Loader from '@/components/loader.vue'
import CatalogueBasicDetails from '@/components/catalogue/modalSteps/catalogue-basic-details.vue'
import CatalogueVisualDetails from '@/components/catalogue/modalSteps/catalogue-visual-details.vue'
import CatalogueAdditionalInformation from '@/components/catalogue/modalSteps/catalogue-additional-information.vue'
import GtmHelper from '@/helpers/GtmHelper'
import useCatalogue from '@/compositions/useCatalogue'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'

export default {
  components: {
    CatalogueBasicDetails,
    CatalogueVisualDetails,
    CatalogueAdditionalInformation,
    Loader,
    Recaptcha
  },
  setup(props, context) {
    const {
      currentStepNumber,
      currentStep,

      goToStep,
      goToNextStep,
      goToPreviousStep
    } = useWizardFlow()

    const {
      recaptchaKey,
      catalogueTranslations,
      modalName,
      items,
      formData: model,

      submitCatalogue,
      revertCatalogueToDefault
    } = useCatalogue()

    const loading = ref(false)
    const errorMessage = ref(null)
    const recaptchaForm = ref(null)
    const { closeLabel } = window.arla.cms.resources.general
    const {
      modalShowCatalogueButtonText,
      modalNextStepButtonText,
      modalPreviousStepButtonText
    } = catalogueTranslations.value
    const submitButtonText = computed(() => (
      currentStep.value.isFinal ? modalShowCatalogueButtonText
        : modalNextStepButtonText
    ))

    const onSubmit = () => {
      if (currentStep.value.isFinal) {
        recaptchaForm.value.verify()
      } else {
        goToNextStep()
        GtmHelper.cataloguePerformStep('next', currentStepNumber.value)
      }
    }

    const onPrevButtonClick = () => {
      goToPreviousStep()
      GtmHelper.cataloguePerformStep('previous', currentStepNumber.value)
    }

    const onSubmitCatalogue = (token) => {
      const {
        product: products,
        recipe: recipes,
        businesscase: businesscases
      } = items.value

      const data = {
        formData: model.value,
        products,
        recipes,
        businesscases
      }

      const trackingData = {
        filledOutFields: Object.entries(model.value)
          .map((x) => (x[1] !== null ? x[0] : ''))
          .filter((x) => !!x)
          .sort()
          .join(','),
        totalItemsAdded: products.length + recipes.length + businesscases.length,
        itemTypesAdded: `products: ${products.length}, recipes: ${recipes.length}, businesscases: ${businesscases.length}`
      }

      loading.value = true
      errorMessage.value = null

      submitCatalogue(data, token, trackingData).then((response) => {
        context.emit('submit')
        goToStep(1)
        revertCatalogueToDefault()
        if (response && response.data) {
          setTimeout(() => {
            window.open(response.data, '_self')
          })
        }
      }).catch(({ response }) => {
        errorMessage.value = response.data
      }).then(() => {
        loading.value = false
      })
    }

    return {
      model,
      modalName,
      catalogueTranslations,
      recaptchaKey,
      recaptchaForm,
      loading,
      errorMessage,
      closeLabel,
      currentStep,
      currentStepNumber,
      submitButtonText,
      modalPreviousStepButtonText,

      onSubmit,
      onSubmitCatalogue,
      onPrevButtonClick
    }
  }
}
</script>
